<app-ct-navbar></app-ct-navbar>
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Support</li>
            </ul>
            <h2>Help & Support</h2>
        </div>
    </div>
    <!-- <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div> -->
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row">
            <!-- <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Contact Details</span>
                    <h2>Get in Touch</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra.</p>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Our Address</h3>
                            <p>7511 Greenwood Ave North,<br>Unit#4339<br>Seattle, WA 98103</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Contact</h3>
                            <p>Mobile: <a href="tel:+44457895789">+1 (xxx) xxx-xxxx</a></p>
                            <p>Mail: <a href="mailto:hello@odemy.com">Support@canitude.com</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Hours of Operation</h3>
                            <p>Monday - Friday: 09:00 - 20:00</p>
                            <p>Sunday & Saturday: 10:30 - 22:00</p>
                        </li>
                    </ul>
                </div>
            </div> -->
            <div class="col-lg-12 col-md-12">
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                    <h2>We're here to help</h2>
                    <p>Many questions are answered in our <a routerLink="/faq">FAQ</a>. If you cannot find your answer via our <a routerLink="/faq">FAQ</a>, please use the contact from below or email us at <a href="mailto:info@canitude.com">info@canitude.com</a> *</p>
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <!-- <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Your name">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Name is required.</div>
                                        <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                        <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <div class="select-box">
                                        <select id="supportType" [(ngModel)]="supportDto.name" name="supportType"  class="form-control form-select">
                                          <option value="select" disabled selected style="color: #000 !important;">Choose Subject</option>
                                          <option value="give compliment">Give Compliment</option>
                                          <option value="make a suggestion">Make a Suggestion</option>
                                          <option value="report a problem">Report a Problem</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input [(ngModel)]="supportDto.email" required name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="Your email address">
                                    <!-- <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div> -->
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea [(ngModel)]="supportDto.message" required  #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="Write your message..."></textarea>
                                    <!-- <div class="alert alert-danger" *ngIf="message.touched && !email.valid">Message is required.</div> -->
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn" [disabled]="!contactForm.valid || supportDto.name == 'select' || !supportDto.email || !supportDto.message" [class.disabled]="!contactForm.valid || supportDto.name == 'select' || !supportDto.email || !supportDto.message"  (click)="contactUs()">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div id="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2686.037701078141!2d-122.35776818451197!3d47.6836877791899!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54901424b6a503d1%3A0x8abcdcbc873c5c4d!2s7511%20Greenwood%20Ave%20N%20%234339%2C%20Seattle%2C%20WA%2098103%2C%20USA!5e0!3m2!1sen!2s!4v1681203064218!5m2!1sen!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div> -->
<app-ct-footer></app-ct-footer>