<!-- <app-ct-navbar></app-ct-navbar> bg-fef8ef bg-f5f7fa -->
<div class="view-all-courses-area">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="overview-box">
          <div class="overview-content">
            <h2>CANITUDE – it’s on your mobile device. Anytime, anywhere.</h2>
            <!-- <h2>Access From Your Mobile, Learn Any Time Any Where</h2> -->
            <p>
              Take advantage of your free time. Learn. Or teach! 
              When you access CANitude from your mobile device, on the go, you can learn a new skill. You can get 
              the answer to a difficult issue from an expert. You can use your time to stretch your knowledge base, to 
              try something you’ve never done before, to share your expertise with others who might need it. 
              Complete a project. Pick up a new hobby. Teach someone the skill you know. 
              With CANitude, the skill you want is right in your hand! 
            </p>
            <div class="btn-box">
              <a class="playstore-btn"><img src="../../../../assets/img/play_market.png" alt="image" /></a>
              <a class="applestore-btn"><img src="../../../../assets/img/app_store.png" alt="image" /></a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="view-all-courses-image">
          <img src="../../../../assets/img/man-with-laptops.png" alt="image" />
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <app-ct-footer></app-ct-footer> -->
