<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a  class="logo" routerLink="/" style="width: 200px;height: 56px;"><img src="../../../../assets/img/Calllab Logo.png" alt="logo"></a>
                    <p>CANitude is a unique online marketplace where Learners benefit from Experts’ advice and knowledge through live sessions. We believe that a mix of a CAN-DO attitude and direct coaching from an Expert is the secret to a successful DYI project.</p>
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Explore</h3>
                    <ul class="footer-links-list">
                        <li><a href="javascript:void(0);" (click)="shareLink()">Refer a Friend</a></li>
                        <li><a href="javascript:void(0);" (click)="scrollAndNavigate('/support')">Help & Support</a></li>
                        <li><a href="javascript:void(0);" (click)="scrollAndNavigate('/how-it-works-detail')">How It Works</a></li>
                        <li><a href="javascript:void(0);" (click)="scrollAndNavigate('/trust-fun')">Trust & Fun</a></li>
                        <li><a href="javascript:void(0);" (click)="scrollAndNavigate('/faq')">FAQ's</a></li>
                        <li><a href="javascript:void(0);" (click)="scrollAndNavigate('/safety')">Safety</a></li>
                        <li><a href="javascript:void(0);" (click)="scrollAndNavigate('/you-can')">You Can</a></li>
                    </ul>
                </div>
            </div>
            
            <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Explore</h3>
                    <ul class="footer-links-list">
                        <li><a href="javascript:void(0);" (click)="shareLink()">Refer a Friend</a></li>
                        <li><a href="javascript:void(0);" routerLink="/support">Help & Support</a></li>
                        <li><a href="javascript:void(0);" routerLink="/how-it-works-detail">How It Works</a></li>
                        <li><a href="javascript:void(0);" routerLink="/trust-fun">Trust & Fun</a></li>
                        <li><a href="javascript:void(0);" routerLink="/faq">FAQ's</a></li>
                        <li><a href="javascript:void(0);" routerLink="/safety">Safety</a></li>
                        <li><a href="javascript:void(0);" routerLink="/you-can">You Can</a></li>
                    </ul>
                </div>
            </div> -->
            <!-- <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Resources</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Student Success</a></li>
                        <li><a routerLink="/">Scholarships</a></li>
                        <li><a routerLink="/">For Business</a></li>
                        <li><a routerLink="/">Go Premium</a></li>
                        <li><a routerLink="/">Team Plans</a></li>
                    </ul>
                </div>
            </div> -->
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3 style="text-decoration: underline;">Contact Us</h3>
                    <ul class="footer-contact-info">
                        <!-- <li><i class='bx bx-map'></i>7511 Greenwood Ave North,<br>Unit#4339<br>Seattle, WA 98103</li> -->
                        <!-- <li><i class='bx bx-phone-call'></i><a href="tel:+44587154756">+1 (xxx) xxx-xxxx</a></li> -->
                        <li><i class='bx bx-envelope'></i><a href="mailto:hello@odemy.com">Info&#64;canitude.com</a></li>
                        <!-- <li><i class='bx bxs-inbox'></i><a href="tel:+557854578964">+55 785 4578964</a></li> -->
                    </ul>
                </div>
                <!-- <div>
                    <div style="box-sizing: border-box;flex: 0 0 auto;display: flex;flex-flow: wrap;-webkit-box-pack: start;justify-content: flex-start;align-items: flex-start;margin-right: -8px;margin-left: -8px;padding: 8px 0px;">
                        <div [ariaColSpan]="4" style="box-sizing: border-box;flex: 0 0 33.3333%;display: flex;flex-direction: column;-webkit-box-pack: start;justify-content: flex-start;-webkit-box-align: stretch;align-items: stretch;max-width: 33.3333%;-webkit-box-flex: 0;padding: 0px 8px;">
                        <a class="store-imgs" style="position: relative;border: 1px solid #a6a6a6;border-radius: 5px;width: 120px;height: 40px;color: #fff;">
                        <span  style="box-sizing: border-box;display: block;overflow: hidden;width: initial;height: initial;background: none;opacity: 1;border: 0;margin: 0;padding: 0;position: absolute;top: 0;left: 0;bottom: 0;right: 0;">
                        <img src="../../../../assets/img/app_store.png" alt="" style="position: absolute;top: 0;left: 0;bottom: 0;right: 0;box-sizing: border-box;padding: 0;border: none; margin: auto; display: block; width: 0; height: 0; min-width: 100%;max-width: 100%; min-height: 100%;max-height: 100%;">
                        </span>
                        </a>
                        </div>
                        <div [ariaColSpan]="4" style="box-sizing: border-box;flex: 0 0 33.3333%;display: flex;flex-direction: column;-webkit-box-pack: start;justify-content: flex-start;-webkit-box-align: stretch;align-items: stretch;max-width: 33.3333%;-webkit-box-flex: 0;padding: 0px 8px;">
                            <a  class="store-imgs" style="position: relative;border: 1px solid #a6a6a6;border-radius: 5px;width: 120px;height: 40px;color: #fff;">
                            <span  style=" box-sizing: border-box;display: block;overflow: hidden;width: initial;height: initial;background: none;opacity: 1;border: 0;margin: 0;padding: 0;position: absolute;top: 0;left: 0;bottom: 0;right: 0;">
                            <img src="../../../../assets/img/play_market.png" alt="" style="position: absolute;top: 0;left: 0;bottom: 0;right: 0;box-sizing: border-box;padding: 0;border: none; margin: auto; display: block; width: 0; height: 0; min-width: 100%;max-width: 100%; min-height: 100%;max-height: 100%;">
                            </span>
                            </a>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>© CANITUDE App is Owned by <a>Canitude</a></p>
                </div>
                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a href="javascript:void(0);" (click)="scrollAndNavigate('/privacy-policy')">Privacy Policy</a></li>
                        <li><a href="javascript:void(0);" (click)="scrollAndNavigate('/terms-and-conditions')">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>